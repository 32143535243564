/* inter-regular - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('/fonts/inter-v11-latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('/fonts/inter-v11-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap; /*https://web.dev/font-display/?utm_source=lighthouse&utm_medium=devtools#how-to-avoid-showing-invisible-text*/
}

/* inter-600 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('/fonts/inter-v11-latin-600.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('/fonts/inter-v11-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}

/* inter-800 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: local(''), url('/fonts/inter-v11-latin-800.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('/fonts/inter-v11-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}

html,
body {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

#__next {
    flex: 1;
    display: flex;
    flex-direction: column;
}

div.otp-input input {
    width: 36px !important;
    height: 36px;
    margin: 0 10px;
}

div.otp-input input::placeholder {
    opacity: 0;
}

div.otp-input input:not(:placeholder-shown),
div.otp-input input:focus {
    border: 2px solid #51cd7c;
    border-radius: 1px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
}

html,
body,
#__next {
    width: 100%;
    min-height: 100%;
    height: 100%;
    margin: 0;
}

body {
    background-color: black;
    color: white;
}
